<template lang="pug">
div(v-if='Inicio')
    //-PC
    v-container(fluid).negro.d-none.d-md-block
        v-row(align='center').mt-8.mb-4
            v-col.pa-0.ma-0
                v-divider.primario.divider_inicial  
            v-col(align='center' justify='center').ml-12.mr-12.pr-12-pl-12.mt-3
                h2(v-html='Inicio.basamos_nuestro_servicios' 
                  style=' font-size: 1.7em').white--text.font-weight-light 
            v-col.pa-0.ma-0
                v-divider.primario.divider_inicial
        v-row.mt-7
            v-col(cols=6).ma-0.pa-0
                v-img(:src='Inicio.arquitectura_e_ingeniería.imagen' height='78vh'
                gradient="to top left, rgba(0, 0, 02 ,.8) , rgba(180, 50, 36, 0.45)" @click='dirigir_a("arquitectura")').imagen_cursor
                    v-container
                        v-row.mt-12
                        v-row.mt-12
                        v-row.mt-12
                        v-row.mt-12
                        v-row(align='center' justify='center')
                            v-img(:src='Inicio.arquitectura_e_ingeniería.ícono' max-width=105 max-height=170)
                        v-row(align='center' justify='center').mt-8
                            h4(v-html='Inicio.arquitectura_e_ingeniería.título' 
                            style='text-align: center; line-height : 30px').white--text.font-weight-light
                        v-row(align='center' justify='center' style='line-height : 1px').mt-6
                            h4(v-html='Inicio.arquitectura_e_ingeniería.texto' 
                            style='text-align: center;').white--text.font-weight-light 
                        v-row(align='center' justify='center')
                            v-btn(icon :to='{name: "arquitectura"}')
                                v-img(:src='Inicio.ícono_flecha' max-width=90)
            v-col(cols=6).ma-0.pa-0
               v-img(:src='Inicio.saneamiento_y_marco_legal.imagen' height='78vh' 
                gradient="to top left, rgba(0, 0, 02 ,.8) , rgba(180, 50, 36, 0.45)" @click='dirigir_a("saneamiento")').imagen_cursor
                    v-container
                        v-row.mt-12
                        v-row.mt-12
                        v-row.mt-12
                        v-row.mt-12
                        v-row(align='center' justify='center')
                            v-img(:src='Inicio.saneamiento_y_marco_legal.ícono' max-width=105 max-height=170)
                        v-row(align='center' justify='center').mt-8
                            h4(v-html='Inicio.saneamiento_y_marco_legal.título'
                            style='text-align: center; line-height : 30px').white--text.font-weight-light
                        v-row(align='center' justify='center' style='line-height : 1px').mt-6
                            h4(v-html='Inicio.saneamiento_y_marco_legal.texto'
                            style='text-align: center;').white--text.font-weight-light 
                        v-row(align='center' justify='center')
                            v-btn(icon :to='{name: "saneamiento"}')
                                v-img(:src='Inicio.ícono_flecha' max-width=90)
    //-Móvil
    v-container(fluid).negro.d-md-none
        v-row(align='center' justify='center').mt-8.mb-4
            h2(v-html='Inicio.basamos_nuestro_servicios_móvil' style='text-align: center; line-height : 14px' ).white--text.font-weight-light 
        v-row.mt-7
            v-col(cols=12).ma-0.pa-0
                v-img(:src='Inicio.arquitectura_e_ingeniería.imagen' height='55vh'
                gradient="to top left, rgba(0, 0, 02 ,.8) , rgba(180, 50, 36, 0.45)" @click='dirigir_a("arquitectura")').imagen_cursor
                    v-container
                        v-row.mt-12
                        v-row.mt-12
                        v-row(align='center' justify='center')
                            v-img(:src='Inicio.arquitectura_e_ingeniería.ícono' max-width=105 max-height=170)
                        v-row(align='center' justify='center').mt-8
                            h4(v-html='Inicio.arquitectura_e_ingeniería.título' 
                            style='text-align: center; line-height : 30px').white--text.font-weight-light
                        v-row(align='center' justify='center' style='line-height : 1px').mt-6
                            h4(v-html='Inicio.arquitectura_e_ingeniería.texto' 
                            style='text-align: center;').white--text.font-weight-light 
                        v-row(align='center' justify='center')
                            v-btn(icon :to='{name: "arquitectura"}')
                                v-img(:src='Inicio.ícono_flecha' max-width=90)
            v-col(cols=12).ma-0.pa-0
               v-img(:src='Inicio.saneamiento_y_marco_legal.imagen' height='55vh' 
                gradient="to top left, rgba(0, 0, 02 ,.8) , rgba(180, 50, 36, 0.45)" @click='dirigir_a("saneamiento")').imagen_cursor
                    v-container
                        v-row.mt-12
                        v-row.mt-12
                        v-row(align='center' justify='center')
                            v-img(:src='Inicio.saneamiento_y_marco_legal.ícono' max-width=105 max-height=170)
                        v-row(align='center' justify='center').mt-8
                            h4(v-html='Inicio.saneamiento_y_marco_legal.título'
                            style='text-align: center; line-height : 30px').white--text.font-weight-light
                        v-row(align='center' justify='center' style='line-height : 1px').mt-6
                            h4(v-html='Inicio.saneamiento_y_marco_legal.texto'
                            style='text-align: center;').white--text.font-weight-light 
                        v-row(align='center' justify='center')
                            v-btn(icon :to='{name: "saneamiento"}')
                                v-img(:src='Inicio.ícono_flecha' max-width=90)
</template>
<script>
export default {
  methods: {
    dirigir_a(ruta){
      this.$router.replace({name: ruta, params: {}})
      this.$vuetify.goTo("#navegador")
    },
  },
}
</script>
<style lang="sass">
    .divider_inicial
      max-height: 10px!important
      height: 9px!important
    .imagen_cursor
        cursor: pointer
</style>